import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_CATEGORY({}, search) {
      try {
        const {
          data: { data },
        } = await apiClient('/category', {
          method: 'GET',
          params: { search },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async ADD_CATEGORY(_, data) {
      try {
        const { data: res } = await apiClient.post('/category', data)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_CATEGORY(_, { data, id }) {
      try {
        const { data: res } = await apiClient.put('/category/' + id, data)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async DELETE_CATEGORY(_, { id }) {
      try {
        const { data: res } = await apiClient.delete('/category/' + id)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {
  },
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
