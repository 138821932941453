<template>
  <a-layout-sider :width="settings.leftMenuWidth" :collapsible="settings.isMobileView ? false : true"
    :collapsed="settings.isMenuCollapsed && !settings.isMobileView" :class="{
    [$style.menu]: true,
    // [$style.white]: settings.menuColor === 'white',
    [$style.gray]: true,
    // [$style.dark]: settings.menuColor === 'dark',
    // [$style.unfixed]: settings.isMenuUnfixed,
    // [$style.shadow]: settings.isMenuShadow,
  }" @collapse="onCollapse">
    <div :class="$style.menuOuter" :style="{
    width:
      settings.isMenuCollapsed && !settings.isMobileView
        ? '80px'
        : settings.leftMenuWidth + 'px',
    height:
      settings.isMobileView || settings.isMenuUnfixed
        ? 'calc(100% - 64px)'
        : 'calc(100% - 110px)',
  }">
      <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <router-link to="/">
            <img v-if="!settings.isMenuCollapsed" width="80" src="../../../assets/img/logo_nodecenter.png" />
            <img v-else width="45" src="/img/favicon.png" />
          </router-link>
        </div>
      </div>
      <perfect-scrollbar :style="{ height: 'calc(100% - 110px)', overflow: 'auto' }" class="mt-4">
        <a-menu v-model:open-keys="openKeys"
          :inline-collapsed="settings.isMobileView ? false : settings.isMenuCollapsed" :mode="'inline'"
          :selected-keys="selectedKeys" :inline-indent="15" :class="$style.navigation" @click="handleClick"
          @openChange="handleOpenChange">
          <template v-for="(item, index) in filteredMenu">
            <template v-if="!item.roles || item.roles.includes(user.role)">
              <a-menu-item-group v-if="item.category" :key="index">
                <template #title>
                  {{ item.title }}
                </template>
              </a-menu-item-group>
              <item v-if="!item.children && !item.category" :key="item.key" :menu-info="item" :styles="$style" />
              <sub-menu v-if="item.children" :key="item.key" :menu-info="item" :styles="$style" />
            </template>
          </template>
        </a-menu>
      </perfect-scrollbar>
    </div>
  </a-layout-sider>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import find from 'lodash/find'
import { getMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'MenuLeft',
  components: { SubMenu, Item },
  setup() {
    const store = useStore()
    const route = useRoute()
    let menuData = computed(() => getMenuData)
    let filteredMenu = ref([])
    let selectedKeys = ref([])
    let openKeys = ref([])
    let settings = computed(() => store.getters.settings)
    let isMenuCollapsed = computed(() => store.getters.settings.isMenuCollapsed)
    let user = computed(() => store.getters['user/user'])
    let pathname = computed(() => route.name)

    const onCollapse = (collapsed, type) => {
      const value = !settings.value.isMenuCollapsed
      store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value })
    }

    const handleClick = e => {
      if (e.key === 'settings') {
        store.commit('CHANGE_SETTING', {
          setting: 'isSettingsOpen',
          value: true,
        })
        return
      }
      selectedKeys.value = [e.key]
    }

    const handleOpenChange = openKeys => {
      openKeys.value = openKeys
    }

    const setSelectedKeys = () => {
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData.value.concat(), 'children'), [
        'title',
        pathname.value,
      ])
      console.log('inidia', pathname.value)
      selectedKeys.value = selectedItem ? [selectedItem.key] : []
    }

    onMounted(() => {
      console.log('semoga masuk kesini')
      // console.log(menuData.value)
      const storedUserData = localStorage.getItem('userData')
      // Memeriksa apakah data tersedia di localStorage
      if (storedUserData) {
        // Mengonversi data dari string JSON menjadi objek JavaScript
        const userData = JSON.parse(storedUserData)

        // Menggunakan kembali data
        console.log('User Role:', userData.user.role);
        if (userData.user.role === 'dcOwner') {
          const menuFilter = menuData.value.filter(e => e.title === 'Dashboard' ||
            e.title === 'Vendors' || e.title === 'SITE MANAGER' || e.title === 'Rollout' || e.title === 'ATS' || e.title === 'Manajemen Akun' ||
            e.title === 'Entrance System' || e.title === 'Change' ||
            e.title === 'Change Request' || e.title === 'Assets')
          filteredMenu.value = menuFilter
        }
        else if (userData.user.role === 'siteManager') {
          const menuFilter = menuData.value.filter(e => e.title === 'EOS Monitoring' || e.title === 'SITE MANAGER' || e.title === 'Rollout' || e.title === 'ATS' || e.title === 'Manajemen Akun')
          filteredMenu.value = menuFilter
        }
        else if (userData.user.role === 'eos') {
          const menuFilter = menuData.value.filter(e => e.title === 'Monitoring' || e.title === 'EOS' || e.title === 'Change' ||
            e.title === 'Change Request' || e.title === 'Manajemen Akun')
          filteredMenu.value = menuFilter
        } else if (userData.user.role === 'admin') {
          const menuFilter = menuData.value.filter(
            e =>
              e.title === 'Dashboard' ||
              e.title === 'SUPER ADMIN' ||
              e.title === 'Users' ||
              e.title === 'Customers' ||
              e.title === 'Vendors' ||
              e.title === 'Base' ||
              e.title === 'Master Data' ||
              e.title === 'Facilities' ||
              e.title === 'Active Device' ||
              e.title === 'Passive Device' ||
              e.title === 'Software' ||
              e.title === 'Menu Pendukung' ||
              e.title === 'Manajemen Akun' ||
              e.title === 'Infrastruktur',
          )
          filteredMenu.value = menuFilter
        } else if (userData.user.role === 'helpDesk') {
          const menuFilter = menuData.value.filter(
            e =>
              e.title === 'Change' ||
              e.title === 'Change Request' ||
              e.title === 'Troubleshoot' ||
              e.title === 'Addition' ||
              e.title === 'Dismantle' ||
              e.title === 'Testing' ||
              e.title === 'Manajemen Akun',
          )
          filteredMenu.value = menuFilter
        } else if (userData.user.role === 'receptionist') {
          const menuFilter = menuData.value.filter(
            e =>
              e.title === 'RECEPTIONIST' ||
              e.title === 'Entrance System' ||
              e.title === 'Menu Pendukung' ||
              e.title === 'Manajemen Akun' ||
              e.title === 'Manajemen Akun',
          )
          filteredMenu.value = menuFilter
        }
      } else {
        console.log('Data not found in localStorage')
      }
      setSelectedKeys()
    })

    watch(pathname, () => setSelectedKeys())
    watch(isMenuCollapsed, () => (openKeys.value = []))

    return {
      menuData,
      selectedKeys,
      openKeys,
      settings,
      user,
      onCollapse,
      handleClick,
      handleOpenChange,
      filteredMenu,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
