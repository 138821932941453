import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async FETCH_ALL_TRANSACTION({}, search) {
      try {
        const {
          data: { data },
        } = await apiClient('/transaction', {
          method: 'GET',
          params: { search },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_TRANSACTION_USER_BY_ADMIN({}, { id, search }) {
      try {
        const {
          data: { data },
        } = await apiClient('/transaction/transaction-user-by-admin/' + id, {
          method: 'GET',
          params: { search },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_STATUS_TRANSACTION({}, { xenditId }) {
      try {
        const {
          data: { data },
        } = await apiClient('/xendit/invoice/callback', {
          method: 'POST',
          params: {
            type: 'manual',
          },
          data: {
            xenditId,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {},
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
