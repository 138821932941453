import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import cookies from 'vue-cookies'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
      },
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          meta: { title: 'Dashboard' },
          component: () => import('./views/course'),
        },
        {
          path: '/assets',
          name: 'Assets',
          meta: { title: 'Assets' },
          component: () => import('./views/assets/dcOwnerAsset.vue'),
        },
        {
          path: '/eos-monitoring',
          name: 'EOS Monitoring',
          meta: { title: 'EOS Monitoring' },
          component: () => import('./views/dashboard/siteManager'),
        },
        {
          path: '/profil',
          name: 'Profil Saya',
          meta: { title: 'Profil Saya' },
          component: () => import('./views/admin/profile'),
        },
        {
          path: '/master',
          name: 'Master',
          redirect: { name: 'Kategori' },
          meta: { title: 'Master' },
        },
        {
          path: '/master/kategori',
          name: 'Kategori',
          meta: { title: 'Kategori' },
          component: () => import('./views/master/category'),
        },
        {
          path: '/master/vendor',
          name: 'Vendor',
          meta: { title: 'Vendor' },
          component: () => import('./views/master/vendor'),
        },
        {
          path: '/garansi',
          name: 'Course',
          meta: { title: 'Course' },
          component: () => import('./views/course'),
        },
        {
          path: '/course/:id',
          name: 'Detail Course',
          meta: { title: 'Detail Course' },
          component: () => import('./views/course/detail'),
        },
        {
          path: '/course/new',
          name: 'Buat Course',
          meta: { title: 'Buat Course' },
          component: () => import('./views/course/new'),
        },
        {
          path: '/akun',
          name: 'Manajemen Akun',
          meta: { title: 'Manajemen Akun' },
          component: () => import('./views/akun'),
        },
        {
          path: '/akun/:id',
          name: 'Detail Akun',
          meta: { title: 'Akun' },
          component: () => import('./views/akun/detail'),
        },
        {
          path: '/banner',
          name: 'Banner',
          meta: { title: 'Banner' },
          component: () => import('./views/banner'),
        },
        {
          path: '/transaksi',
          name: 'Transaksi',
          meta: { title: 'Transaksi' },
          component: () => import('./views/transaction'),
        },
        {
          path: '/users',
          name: 'Users',
          meta: { title: 'Users' },
          component: () => import('./views/users'),
        },
        {
          path: '/users/create',
          name: 'Users Create',
          meta: { title: 'Users Create' },
          component: () => import('./views/users/create.vue'),
        },
        {
          path: '/users/:id',
          name: 'Users Detail',
          meta: { title: 'Users Detail' },
          component: () => import('./views/users/detail.vue'),
        },
        {
          path: '/receptionist',
          name: 'Entrance System',
          meta: { title: 'Entrance System' },
          component: () => import('./views/receptionist'),
        },
        {
          path: '/receptionist/create',
          name: 'Receptionist Create',
          meta: { title: 'Receptionist Create' },
          component: () => import('./views/receptionist/create.vue'),
        },
        {
          path: '/receptionist/:id',
          name: 'Receptionist Detail',
          meta: { title: 'Receptionist Detail' },
          component: () => import('./views/receptionist/detail.vue'),
        },
        {
          path: '/customers',
          name: 'Customers',
          meta: { title: 'Customers' },
          component: () => import('./views/customers'),
        },
        {
          path: '/customers/create',
          name: 'Customers Create',
          meta: { title: 'Customers Create' },
          component: () => import('./views/customers/create.vue'),
        },
        {
          path: '/customers/:id',
          name: 'Customers Detail',
          meta: { title: 'Customers Detail' },
          component: () => import('./views/customers/detail.vue'),
        },
        {
          path: '/vendors',
          name: 'Vendors',
          meta: { title: 'Vendors' },
          component: () => import('./views/vendors'),
        },
        {
          path: '/vendors/create',
          name: 'Vendors Create',
          meta: { title: 'Vendors Create' },
          component: () => import('./views/vendors/create.vue'),
        },
        {
          path: '/vendors/:id',
          name: 'Vendors Detail',
          meta: { title: 'Vendors Detail' },
          component: () => import('./views/vendors/detail.vue'),
        },
        {
          path: '/monitoring',
          name: 'Monitoring',
          meta: { title: 'Monitoring' },
          component: () => import('./views/monitoring'),
        },
        {
          path: '/monitoring/detail/:id',
          name: 'Monitoring Detail',
          meta: { title: 'Detail Monitoring' },
          component: () => import('./views/monitoring/detail'),
        },
        {
          path: '/monitoring/troubleshoot',
          name: 'Troubleshoot',
          meta: { title: 'Troubleshoot' },
          component: () => import('./views/troubleshoot'),
        },
        {
          path: '/monitoring/troubleshoot/create',
          name: 'Add Troubleshoot',
          meta: { title: 'Create Troubleshoot' },
          component: () => import('./views/troubleshoot/create.vue'),
        },
        {
          path: '/monitoring/troubleshoot/detail/:id',
          name: 'Detail Troubleshoot',
          meta: { title: 'Detail Troubleshoot' },
          component: () => import('./views/troubleshoot/detail.vue'),
        },
        {
          path: '/monitoring/addition',
          name: 'Addition',
          meta: { title: 'Addition' },
          component: () => import('./views/addition'),
        },
        {
          path: '/monitoring/addition/detail/:id',
          name: 'Detail Addition',
          meta: { title: 'Detail Addition' },
          component: () => import('./views/addition/detail.vue'),
        },
        {
          path: '/monitoring/dismantle',
          name: 'Dismantle',
          meta: { title: 'Dismantle' },
          component: () => import('./views/dismantle'),
        },
        {
          path: '/monitoring/dismantle/detail/:id',
          name: 'Detail Dismantle',
          meta: { title: 'Detail Dismantle' },
          component: () => import('./views/dismantle/detail.vue'),
        },
        {
          path: '/monitoring/testing',
          name: 'Testing',
          meta: { title: 'Testing' },
          component: () => import('./views/testing'),
        },
        {
          path: '/rollout',
          name: 'Rollout',
          meta: { title: 'Rollout' },
          component: () => import('./views/rollout'),
        },
        {
          path: '/ats',
          name: 'ATS',
          meta: { title: 'ATS' },
          component: () => import('./views/ats'),
        },
        {
          path: '/monitoring/testing/detail/:id',
          name: 'Detail Testing',
          meta: { title: 'Detail Testing' },
          component: () => import('./views/testing/detail.vue'),
        },
        {
          path: '/request',
          name: 'Change Request',
          meta: { title: 'Change Request' },
          component: () => import('./views/changeRequest'),
        },
        {
          path: '/request/create',
          name: 'Buat Request',
          meta: { title: 'Buat Request' },
          component: () => import('./views/changeRequest/create.vue'),
        },
        {
          path: '/request/detail/:id',
          name: 'Detail Request',
          meta: { title: 'Detail Request' },
          component: () => import('./views/changeRequest/detail.vue'),
        },

        {
          path: '/base/lokasi',
          name: 'Base Lokasi',
          meta: { title: 'Base Lokasi' },
          component: () => import('./views/base/location'),
        },
        {
          path: '/base/lokasi/buat',
          name: 'Buat Base Lokasi',
          meta: { title: 'Buat Base Lokasi' },
          component: () => import('./views/base/location/create.vue'),
        },
        {
          path: '/base/lokasi/detail/:id',
          name: 'Detail Base Lokasi',
          meta: { title: 'Detail Base Lokasi' },
          component: () => import('./views/base/location/detail.vue'),
        },
        {
          path: '/base/ruangan',
          name: 'Base Ruangan',
          meta: { title: 'Base Ruangan' },
          component: () => import('./views/base/room'),
        },
        {
          path: '/base/ruangan/detail/:id',
          name: 'Detail Base Ruangan',
          meta: { title: 'Detail Base Ruangan' },
          component: () => import('./views/base/room/detail.vue'),
        },
        {
          path: '/base/ruangan/buat',
          name: 'Buat Base Ruangan',
          meta: { title: 'Buat Base Ruangan' },
          component: () => import('./views/base/room/create.vue'),
        },

        {
          path: '/base/manufaktur',
          name: 'Base Manufaktur',
          meta: { title: 'Base Manufaktur' },
          component: () => import('./views/base/manufacture'),
        },
        {
          path: '/base/manufaktur/detail/:id',
          name: 'Detail Base Manufaktur',
          meta: { title: 'Detail Base Manufaktur' },
          component: () => import('./views/base/manufacture/detail.vue'),
        },
        {
          path: '/base/manufaktur/buat',
          name: 'Buat Base Manufaktur',
          meta: { title: 'Buat Base Manufaktur' },
          component: () => import('./views/base/manufacture/create.vue'),
        },
        {
          path: '/facilities',
          name: 'Facility',
          meta: { title: 'Facility' },
          // component: () => import('./views/assets'),
          redirect: '/facilities/access_door',
          // sensitive: true,
          children: [
            {
              path: 'battery',
              name: 'Facility Battery',
              meta: { title: 'Facility Battery' },
              component: () => import('./views/assets'),
              meta: {
                role: ['Admin', 'EOS'],
              },
            },
            {
              path: 'cctv',
              name: 'Facility CCTV',
              meta: { title: 'Facility CCTV' },
              component: () => import('./views/assets'),
            },
            {
              path: 'electrical_panel',
              name: 'Facility Electrical Panel',
              meta: { title: 'Facility Electrical Panel' },
              component: () => import('./views/assets'),
            },
            {
              path: 'env_monitoring',
              name: 'Facility Env Monitoring',
              meta: { title: 'Facility Env Monitoring' },
              component: () => import('./views/assets'),
            },
            {
              path: 'fire_extinguisher',
              name: 'Facility Fire Extinguisher',
              meta: { title: 'Facility Fire Extinguisher' },
              component: () => import('./views/assets'),
            },
            {
              path: 'fire_piping',
              name: 'Facility Fire Piping',
              meta: { title: 'Facility Fire Piping' },
              component: () => import('./views/assets'),
            },
            {
              path: 'fire_suppresion',
              name: 'Facility Fire Suppresion',
              meta: { title: 'Facility Fire Suppresion' },
              component: () => import('./views/assets'),
            },
            {
              path: 'generator_set',
              name: 'Facility Generator Set',
              meta: { title: 'Facility Generator Set' },
              component: () => import('./views/assets'),
            },
            {
              path: 'ups',
              name: 'Facility UPS',
              meta: { title: 'Facility UPS' },
              component: () => import('./views/assets'),
            },
            {
              path: 'access_door',
              name: 'Facility Access Door',
              meta: { title: 'Facility Access Door' },
              component: () => import('./views/assets'),
            },
            {
              path: 'precision_ac',
              name: 'Facility Precision AC',
              meta: { title: 'Facility Precision AC' },
              component: () => import('./views/assets'),
            },
            {
              path: 'standard_ac',
              name: 'Facility Standard AC',
              meta: { title: 'Facility Standard AC' },
              component: () => import('./views/assets'),
            },
            {
              path: 'create',
              name: 'Facility Create',
              meta: { title: 'Facility Create' },
              component: () => import('./views/assets/create.vue'),
            },
            {
              path: 'detail/:id',
              name: 'Facility Detail',
              meta: { title: 'Facility Detail' },
              component: () => import('./views/assets/detail.vue'),
            },
          ],
        },
        {
          path: '/active',
          name: 'Active',
          meta: { title: 'Active' },
          // component: () => import('./views/assets'),
          redirect: '/active/access_point',
          children: [
            {
              path: 'access_point',
              name: 'Active Access Point',
              meta: { title: 'Active Access Point' },
              component: () => import('./views/assets'),
            },
            {
              path: 'firewall',
              name: 'Active Firewall',
              meta: { title: 'Active Firewall' },
              component: () => import('./views/assets'),
            },
            {
              path: 'router',
              name: 'Active Router',
              meta: { title: 'Active Router' },
              component: () => import('./views/assets'),
            },
            {
              path: 'server',
              name: 'Active Server',
              meta: { title: 'Active Server' },
              component: () => import('./views/assets'),
            },
            {
              path: 'Switch',
              name: 'Active Switch',
              meta: { title: 'Active Switch' },
              component: () => import('./views/assets'),
            },
            {
              path: 'storage',
              name: 'Active Storage',
              meta: { title: 'Active Storage' },
              component: () => import('./views/assets'),
            },
            {
              path: 'create',
              name: 'Active Create',
              meta: { title: 'Active Create' },
              component: () => import('./views/assets/create.vue'),
            },
            {
              path: 'detail/:id',
              name: 'Active Detail',
              meta: { title: 'Active Detail' },
              component: () => import('./views/assets/detail.vue'),
            },
          ],
        },
        {
          path: '/passive',
          name: 'Passive',
          meta: { title: 'Passive' },
          // component: () => import('./views/assets'),
          redirect: '/passive/utp_cable',
          children: [
            {
              path: 'utp_cable',
              name: 'Passive UTP Cable',
              meta: { title: 'Passive UTP Cable' },
              component: () => import('./views/assets'),
            },
            {
              path: 'utp_patch_panel',
              name: 'Passive UTP Patch Panel',
              meta: { title: 'Passive UTP Patch Panel' },
              component: () => import('./views/assets'),
            },
            {
              path: 'fo_cable',
              name: 'Passive FO Cable',
              meta: { title: 'Passive FO Cable' },
              component: () => import('./views/assets'),
            },
            {
              path: 'fo_patch_panel',
              name: 'Passive FO Patch Panel',
              meta: { title: 'Passive FO Patch Panel' },
              component: () => import('./views/assets'),
            },
            {
              path: 'rack',
              name: 'Passive Rack',
              meta: { title: 'Passive Rack' },
              component: () => import('./views/assets'),
            },
            {
              path: 'create',
              name: 'Passive Create',
              meta: { title: 'Passive Create' },
              component: () => import('./views/assets/create.vue'),
            },
            {
              path: 'detail/:id',
              name: 'Passive Detail',
              meta: { title: 'Passive Detail' },
              component: () => import('./views/assets/detail.vue'),
            },
          ],
        },
        {
          path: '/software',
          name: 'Software',
          meta: { title: 'Software' },
          // component: () => import('./views/assets'),
          redirect: '/software/list',
          children: [
            {
              path: 'list',
              name: 'Software',
              meta: { title: 'Software' },
              component: () => import('./views/assets'),
            },
            {
              path: 'create',
              name: 'Add Software',
              meta: { title: 'Add Software' },
              component: () => import('./views/assets/create'),
            },
            {
              path: 'detail/:id',
              name: 'Software Detail',
              meta: { title: 'Software Detail' },
              component: () => import('./views/assets/detail.vue'),
            },
          ],
        },
        {
          path: '/infrastruktur',
          name: 'Infrastruktur',
          meta: { title: 'Infrastruktur' },
          // component: () => import('./views/assets'),
          redirect: '/infrastruktur/list',
          children: [
            {
              path: 'list',
              name: 'Infrastruktur',
              meta: { title: 'Infrastruktur' },
              component: () => import('./views/assets'),
            },
            {
              path: 'create',
              name: 'Add Infrastruktur',
              meta: { title: 'Add Infrastruktur' },
              component: () => import('./views/assets/create'),
            },
            {
              path: 'detail/:id',
              name: 'Infrastruktur Detail',
              meta: { title: 'Infrastruktur Detail' },
              component: () => import('./views/assets/detail.vue'),
            },
          ],
        },
      ],
    },

    // System Pages
    {
      path: '/',
      component: AuthLayout,
      meta: { authRequired: false, isAuth: false },
      name: 'auth',
      children: [
        {
          path: '/masuk',
          name: 'masuk',
          meta: {
            title: 'Masuk',
          },
          component: () => import('@/views/auth/login'),
        },
        {
          path: 'register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/forgot-password',
          name: 'Lupa Password',
          meta: {
            title: 'Lupa Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },
    {
      path: '/404',
      name: 'route404',
      meta: {
        title: 'Error 404',
      },
      component: () => import('./views/auth/404'),
    },
    {
      path: '/500',
      meta: {
        title: 'Error 500',
      },
      component: () => import('./views/auth/500'),
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 300)
  const storedUserData = localStorage.getItem('userData')
  const userData = JSON.parse(storedUserData)
  console.log('inirouter', userData)
  if (!userData) {
    if (to.path !== '/masuk') {
      return next('/masuk')
    }
  }
  // else if (userData.user.role === 'siteManager') {
  //   return next()
  // } else {
  // }
  next()

  // console.log(to, '<<<<<ROUTER')
  // if (matched.route.role.includes(user.role)) {
  //   next
  // }
  // const sessionId = cookies.get('sessionId')
  // // console.log(to, from)
  // if (to.matched.some(record => record.meta.authRequired)) {
  //   if (!sessionId) {
  //     next({
  //       // name: 'masuk',
  //       // query: { redirect: to.fullPath },
  //     })
  //   } else {
  //     next()
  //   }
  // } else {
  //   if (to.matched.some(record => record.meta.isAuth) && sessionId) next({ name: 'Master' })
  //   next()
  // }
})

export default router
