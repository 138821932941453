import { createStore } from 'vuex'
import user from './user'
import settings from './settings'
import course from './course'
import category from './category'
import vendor from './vendor'
import banner from './banner'
import akun from './akun'
import transaction from './transaction'
import discountedCourse from './discountedCourse'
import admin from './admin'

export default createStore({
  modules: {
    user,
    settings,
    course,
    category,
    vendor,
    banner,
    akun,
    transaction,
    discountedCourse,
    admin,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
})
