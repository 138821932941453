import router from '@/router'
import { notification } from 'ant-design-vue'
import apiClient from '@/services/axios'
import VueCookies from 'vue-cookies'

export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    role: '',
    email: '',
    avatar: '',
    authorized: false,
    loading: false,
    accountFetchIsTouched: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async LOGIN({ commit, dispatch, rootState }, { email, password }) {
      try {
        const { data } = await apiClient.post('/users/login', {
          email,
          password,
        })
        console.log(data, '<<<<<STORE USER')
        const dataUser = {
          user: {
            id: data.user.id,
            name: data.user.name,
            role: data.user.role,
            email: data.user.email,
            accessToken: data.accessToken,
          },
          sessionId: data.accessToken,
        }
        localStorage.setItem('userData', JSON.stringify(dataUser))
        return new Promise((resolve, reject) => {
          resolve({ user: dataUser.user, session: data.accessToken })
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async LOAD_CURRENT_ACCOUNT({ commit, dispatch, rootState }, { token }) {
      try {
        const outerData = await apiClient.get('/users/load?accesstoken=' + token)
        if (!outerData) router.push('/masuk')
        console.log(outerData, 'outerData')
        const user = outerData.data.userData
        console.log(user, 'user')
        commit('SET_STATE', {
          name: user.name,
          email: user.email,
          sessionId: token,
          id: user.id,
          role: user.role,
        })
        VueCookies.set(
          'user',
          { id: user.id, email: user.email, name: 'Admin', role: 'admin' },
          null,
          '/',
        )
        // return new Promise((resolve, reject) => {
        //   // resolve({})
        // })
      } catch (err) {
        // router.push('/masuk')
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async LOGOUT({ commit, rootState }) {
      try {
        // await apiClient.post('/account/logout')
        VueCookies.remove('sessionId')
        VueCookies.remove('user')
        localStorage.clear()
        commit('SET_STATE', { sessionId: null })
        router.push({ name: 'masuk' })
      } catch (err) {
        console.log(err)
      }
    },
  },
  getters: {
    user: state => state,
  },
}
