import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_ALL_COURSE(_, { name }) {
      try {
        const { data: { data } } = await apiClient.get('/course/', { params: { name }})
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_ALL_PUBLISHED_WITH_EXCEPT_COURSE(_, courseExcludes) {
      try {
        const {
          data: { data },
        } = await apiClient('/course/published-exclude-course', {
          params: {
            courseExcludes,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_COURSE_BY_ID(_, { id }) {
      try {
        const {
          data: { data },
        } = await apiClient.get('/course/id/' + id)
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async JOIN_COURSE_ADMIN(_, { payload }) {
      try {
        const {
          data: { data },
        } = await apiClient('/my-course/join-by-admin', {
          method: 'POST',
          data: {
            ...payload,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async ADD_COURSE(_, data) {
      try {
        const { data: { data:res } } = await apiClient.post('/course/', data)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_COURSE(_, payload) {
      try {
        const { data: { data:res } } = await apiClient.put('/course/' + payload.id, payload.data)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },

    async DELETE_VIDEO_LESSON(_, { idSection, idLesson, idCourse, filename }) {
      try {
        console.log(filename)
        const { data: { data:res } } = await apiClient.put('/course/delete-video/' + idLesson, { idSection, idCourse, filename })
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },

    async UPDATE_SECTION_COURSE(_, { section, idCourse }) {
      try {
        const { data: { data:res } } = await apiClient.put('/course/' + idCourse + '/section/' + section._id, section)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },

    async ADD_SECTION_COURSE(_, { section, idCourse }) {
      try {
        const { data: { data:res } } = await apiClient.post('/course/' + idCourse + '/section/', section)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },

    async DELETE_SECTION_COURSE(_, { idCourse, idSection }) {
      try {
        const { data: { data:res } } = await apiClient.delete('/course/' + idCourse + '/section/' + idSection)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },

    async DELETE_LESSON(_, { idCourse, idSection, idLesson }) {
      try {
        // console.log('jkdsgk',idCourse, idSection, idLesson)
        const { data: { data:res } } = await apiClient.delete('/course/' + idCourse + '/lesson/' + idLesson, { params: { idSection }})
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_VIDEO_METADATA(_, { payload, idLesson }) {
      try {
        // console.log('jkdsgk',idCourse, idSection, idLesson)
        const { data: { data:res } } = await apiClient.put('/course/lesson/' + idLesson + '/metadata-video', payload)
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async DELETE_COURSE(_, { idCourse }) {
      try {
        // console.log('jkdsgk',idCourse, idSection, idLesson)
        const { data: { data:res } } = await apiClient.delete('/course/' + idCourse)
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async DELETE_COURSE_BANNER(_, { idCourse }) {
      try {
        // console.log('jkdsgk',idCourse, idSection, idLesson)
        const { data: { data:res } } = await apiClient.delete('/course/' + idCourse + '/banner')
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {},
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
