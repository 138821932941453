<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-avatar shape="square" size="large" :class="$style.avatar">
        <template #icon>
          <UserOutlined />
        </template>
      </a-avatar>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item @click="logout">
          <!-- TO DO DUMMY FUNCTION, UNCOMMENT LINE BELOW -->
          <!-- <a class="text-danger" @click="logout"> -->
          <a class="text-danger">
            <i class="fe fe-log-out" />
            Keluar
          </a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { UserOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    UserOutlined,
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])

    const logout = () => {
      // TO DO DUMMY FUNCTION, UNCOMMENT LINE BELOW
      store.dispatch('user/LOGOUT')
      const router = useRouter()
      router.push('/masuk')
    }

    return {
      user,
      logout,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
