import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_ALL_AKUN_CUSTOMER({}, search) {
      try {
        const {
          data: { data },
        } = await apiClient.get('/user/customer', { params: { search } })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_DETAIL_AKUN({}, id) {
      try {
        const {
          data: { data },
        } = await apiClient.get(`/user/${id}`)
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_MYCOURSE({}, id) {
      try {
        const {
          data: { data },
        } = await apiClient.get(`/my-course/${id}`)
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async TAKEDOWN_MYCOURSE({}, id) {
      try {
        console.log(id)
        const { data: res } = await apiClient.delete(`/my-course/takedown/${id}`)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async ADD_AKUN_CUSTOMER(_, data) {
      try {
        const { data: res } = await apiClient.post('/user/register', data)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_AKUN_CUSTOMER(_, { data, id }) {
      try {
        const { data: res } = await apiClient('/user/' + id, {
          method: 'PUT',
          data: {
            editUserObj: data,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async DELETE_AKUN_CUSTOMER(_, { id }) {
      try {
        const { data: res } = await apiClient.delete('/user/' + id)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {},
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
